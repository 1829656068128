import React from "react"
import './Cd.scss'

const Cd = ({cd}) => {
  return (
    <div className="Cd card">
      <header className="card-header p-md ">
        <div className="Cd__Title">
          <h5 className="title is-5">
            {cd.title}
          </h5>
          <h6 className="subtitle is-6">
            {cd.subtitle}
          </h6>
        </div>
        <div className="Cd__Studio">
          {cd.studio}
        </div>
      </header>
      {cd.desc && <div className="card-content p-md">
        {cd.desc}
      </div>}
    </div>
  )
}

export default Cd