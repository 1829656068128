import React from "react"
import './Title.scss'

function Title({children, level, className}) {
  const Tag = level ? `h${level}` : 'h2'
  const levelClass = level ? `is-${level}` : 'is-2'

  return <Tag className={`HeaderTitle title ${levelClass} is-family-secondary ${className}`}>{children}</Tag>
}

export default Title;