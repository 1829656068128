import React from "react"
import Title from "../Title/Title"
import Cd from "./Cd/Cd"

const CDs = ({ cds }) => {
  return <div>
    <Title>CDs</Title>

    {cds.map(cd => <a href={cd.link} key={cd.title} className="m-t-md is-block">
      <Cd cd={cd} />
    </a>)}
  </div>
}

export default CDs
