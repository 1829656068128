import { graphql } from "gatsby"
import React from 'react'
import Layout from "../components/layout"
import CDs from "../components/CDs/CDs"

const CDsTmp = ({data: { markdownRemark: {frontmatter: {cds}}}}) => (
  <Layout>
    <CDs cds={cds}/>
  </Layout>
)

export default CDsTmp

export const pageQuery = graphql`
  query CDs($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        cds {
          title
          subtitle
          studio
          desc
          link
        }  
      }
    }
  }
`

